import React, { useState, useEffect } from "react";
import "./DataUsable.css";
import "./DataUsableMediaquery.css";

const DataUsable = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="dataUsable_section container-fluid">
      <div className="container">
        <div className="row text-center imp-points marg-7">
          <h2 className="dataUsable_heading">Data in usable format</h2>
        </div>
        <div className="row text-center">
          <p className="dataUsable_subheading">
            Consume the data with multiple views. Designed for convenience and
            to address different usecases.
          </p>
        </div>

        {width < 768 ? (
          <div className="block-tabs">
            <div className="d-flex flex-row justify-content-between scrolls">
              {/* <HorizontalScroll
              style={{ position: "absolute", width: "100%" }}
              config={{ stiffness: 1, damping: 1 }}
              animValues={1}
            > */}
              <div className="usable-tabs text-center">
                <button
                  type="button"
                  className={toggleState === 1 ? "btn active-tabs" : "btn tabs"}
                  onClick={() => toggleTab(1)}
                >
                  Map view
                </button>
              </div>
              <div className="usable-tabs text-center">
                <button
                  type="button"
                  className={toggleState === 2 ? "btn active-tabs" : "btn tabs"}
                  onClick={() => toggleTab(2)}
                >
                  Calender View
                </button>
              </div>
              <div className="usable-tabs text-center">
                <button
                  type="button"
                  className={toggleState === 3 ? "btn active-tabs" : "btn tabs"}
                  onClick={() => toggleTab(3)}
                >
                  List View
                </button>
              </div>
              <div className="usable-tabs text-center">
                <button
                  type="button"
                  className={toggleState === 4 ? "btn active-tabs" : "btn tabs"}
                  onClick={() => toggleTab(4)}
                >
                  Dashboard View
                </button>
              </div>
              <div className="usable-tabs text-center">
                <button
                  type="button"
                  className={toggleState === 5 ? "btn active-tabs" : "btn tabs"}
                  onClick={() => toggleTab(5)}
                >
                  API Format
                </button>
              </div>
              {/* </HorizontalScroll> */}
            </div>
          </div>
        ) : (
          <div className="block-tabs">
            <div className="d-flex flex-row justify-content-between">
              <div className="usable-tabs text-center">
                <button
                  type="button"
                  className={toggleState === 1 ? "btn active-tabs" : "btn tabs"}
                  onClick={() => toggleTab(1)}
                >
                  Map view
                </button>
              </div>
              <div className="usable-tabs text-center">
                <button
                  type="button"
                  className={toggleState === 2 ? "btn active-tabs" : "btn tabs"}
                  onClick={() => toggleTab(2)}
                >
                  Calender View
                </button>
              </div>
              <div className="usable-tabs text-center">
                <button
                  type="button"
                  className={toggleState === 3 ? "btn active-tabs" : "btn tabs"}
                  onClick={() => toggleTab(3)}
                >
                  List View
                </button>
              </div>
              <div className="usable-tabs text-center">
                <button
                  type="button"
                  className={toggleState === 4 ? "btn active-tabs" : "btn tabs"}
                  onClick={() => toggleTab(4)}
                >
                  Dashboard View
                </button>
              </div>
              <div className="usable-tabs text-center">
                <button
                  type="button"
                  className={toggleState === 5 ? "btn active-tabs" : "btn tabs"}
                  onClick={() => toggleTab(5)}
                >
                  API Format
                </button>
              </div>
            </div>
          </div>
        )}

        {/*  */}
        <div className="content-tabs mobiletabs">
          <div
            className={
              toggleState === 1 ? "content active-content " : "content"
            }
          >
            <div className="d-flex flex-column justify-content-center">
              <h5 className="content-heading">
                See the event spread by type and dates
              </h5>
              <img
                src={process.env.REACT_APP_CDN + "/Images/mapview.png"}
                alt="mapview"
                className="tab-sec-img"
              />
            </div>
          </div>
          <div
            className={
              toggleState === 2 ? "content active-content " : "content"
            }
          >
            <div className="d-flex flex-column justify-content-center">
              <h5 className="content-heading">
                See Demand color coded for easy visualisation
              </h5>
              <img
                src={process.env.REACT_APP_CDN + "/Images/calendarView.png"}
                alt="calenderview"
                className="tab-sec-img"
              />
            </div>
          </div>
          <div
            className={
              toggleState === 3 ? "content active-content " : "content"
            }
          >
            <div className="d-flex flex-column justify-content-center">
              <h5 className="content-heading">
                Scroll through event type and duration
              </h5>
              <img
                src={process.env.REACT_APP_CDN + "/Images/Listview.png"}
                alt="Listview"
                className="tab-sec-img"
              />
            </div>
          </div>
          <div
            className={
              toggleState === 4 ? "content active-content " : "content"
            }
          >
            <div className="d-flex flex-column justify-content-center">
              <h5 className="content-heading">
                Get the data to use in your internal models
              </h5>
              <img
                src={process.env.REACT_APP_CDN + "/Images/dashboardView.png"}
                alt="apiformat"
                className="tab-sec-img"
              />
            </div>
          </div>
          <div
            className={
              toggleState === 5 ? "content active-content " : "content"
            }
          >
            <div className="d-flex flex-column justify-content-center">
              <h5 className="content-heading">
                Get the data to use in your internal models
              </h5>
              <img
                src={process.env.REACT_APP_CDN + "/Images/apiformat.png"}
                alt="apiformat"
                className="tab-sec-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataUsable;
