const EmailTemplate = (params) =>{
    return `
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

      <head>
        <!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->
        <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
        <meta content="width=device-width" name="viewport" />
        <!--[if !mso]><!-->
        <meta content="IE=edge" http-equiv="X-UA-Compatible" />
        <!--<![endif]-->
        <title></title>
        <!--[if !mso]><!-->
        <link href="https://fonts.googleapis.com/css?family=Lato" rel="stylesheet" type="text/css" />
        <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" type="text/css" />
        <!--<![endif]-->
        <style type="text/css">
          @import url(https://fonts.googleapis.com/css?family=Roboto:400,700,400italic,700italic&subset=latin,cyrillic);

          body {
            margin: 0;
            padding: 0;
            mso-line-height-rule: exactly;
            min-width: 100%;
          }

          .wrapper {
            display: table;
            table-layout: fixed;
            width: 100%;
            min-width: 620px;
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
          }

          body,
          .wrapper {
            background-color: #ffffff;
          }

          /* Basic */
          table {
            border-collapse: collapse;
            border-spacing: 0;
          }

          table.center {
            margin: 0 auto;
            width: 602px;
          }

          td {
            padding: 0;
            vertical-align: top;
          }

          .spacer,
          .border {
            font-size: 1px;
            line-height: 1px;
          }

          .spacer {
            width: 100%;
            line-height: 16px
          }

          .border {
            background-color: #e0e0e0;
            width: 1px;
          }

          .padded {
            padding: 0 24px;
          }

          img {
            border: 0;
            -ms-interpolation-mode: bicubic;
          }

          .image {
            font-size: 12px;
          }

          .image img {
            display: block;
          }

          strong,
          .strong {
            font-weight: 700;
          }

          h1,
          h2,
          h3,
          p,
          ol,
          ul,
          li {
            margin-top: 0;
          }

          ol,
          ul,
          li {
            padding-left: 0;
          }

          a {
            text-decoration: none;
            color: #616161;
          }

          .btn {
            background-color: #2196F3;
            border: 1px solid #2196F3;
            border-radius: 2px;
            color: #ffffff;
            display: inline-block;
            font-family: Roboto, Helvetica, sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 36px;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            width: 200px;
            height: 36px;
            padding: 0 8px;
            margin: 0;
            outline: 0;
            outline-offset: 0;
            -webkit-text-size-adjust: none;
            mso-hide: all;
          }

          /* Top panel */
          .title {
            text-align: left;
          }

          .subject {
            text-align: right;
          }

          .title,
          .subject {
            width: 300px;
            padding: 8px 0;
            color: #616161;
            font-family: Roboto, Helvetica, sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
          }

          /* Header */
          .logo {
            padding: 16px 0;
          }

          /* Logo */
          .logo-image {}

          /* Main */
          .main {
            -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
            -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
          }

          /* Content */
          .columns {
            margin: 0 auto;
            width: 600px;
            background-color: #ffffff;
            font-size: 14px;
          }

          .column {
            text-align: left;
            background-color: #ffffff;
            font-size: 14px;
          }

          .column-top {
            font-size: 24px;
            line-height: 24px;
          }

          .content {
            width: 100%;
          }

          .column-bottom {
            font-size: 8px;
            line-height: 8px;
          }

          .content h1 {
            margin-top: 0;
            margin-bottom: 16px;
            color: #212121;
            font-family: Roboto, Helvetica, sans-serif;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
          }

          .content p {
            margin-top: 0;
            margin-bottom: 16px;
            color: #212121;
            font-family: Roboto, Helvetica, sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }

          .content .caption {
            color: #616161;
            font-size: 12px;
            line-height: 20px;
          }

          /* Footer */
          .signature,
          .subscription {
            vertical-align: bottom;
            width: 300px;
            padding-top: 8px;
            margin-bottom: 16px;
          }

          .signature {
            text-align: left;
          }

          .subscription {
            text-align: right;
          }

          .signature p,
          .subscription p {
            margin-top: 0;
            margin-bottom: 8px;
            color: #616161;
            font-family: Roboto, Helvetica, sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
          }

          @media only screen and (min-width: 0) {
            .wrapper {
              text-rendering: optimizeLegibility;
            }
          }

          @media only screen and (max-width: 620px) {
            [class=wrapper] {
              min-width: 302px !important;
              width: 100% !important;
            }

            [class=wrapper] .block {
              display: block !important;
            }

            [class=wrapper] .hide {
              display: none !important;
            }

            [class=wrapper] .top-panel,
            [class=wrapper] .header,
            [class=wrapper] .main,
            [class=wrapper] .footer {
              width: 302px !important;
            }

            [class=wrapper] .title,
            [class=wrapper] .subject,
            [class=wrapper] .signature,
            [class=wrapper] .subscription {
              display: block;
              float: left;
              width: 300px !important;
              text-align: center !important;
            }

            [class=wrapper] .signature {
              padding-bottom: 0 !important;
            }

            [class=wrapper] .subscription {
              padding-top: 0 !important;
            }
          }
        </style>
      </head>
      <center class="wrapper">
        <table class="top-panel center" width="602" border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td class="title" width="300">evenTELI</td>
            </tr>
            <tr>
              <td class="border">&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <div class="spacer">&nbsp;</div>
        <table class="main center" width="602" border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td class="column">
                <div class="column-top">&nbsp;</div>
                <table class="content" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td class="padded">
                        <h1>New Lead from the Website</h1>
                        <p>Contact Info:</p>
                        <table style="width:100%">
                          <tr>
                            <td><strong>Name</strong></td>
                            <td>${params.name}</td>
                          </tr>
                          <tr>
                            <td><strong>Email<strong></td>
                            <td>${params.email}</td>
                          </tr>
                          <tr>
                            <td><strong>Phone</strong></td>
                            <td>${params.phone}</td>
                          </tr>
                          <tr>
                            <td><strong>Services</strong></td>
                            <td>${params.services}</td>
                          </tr>
                        </table><br>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="column-bottom">&nbsp;</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="spacer">&nbsp;</div>
      </center>
      </body>

      </html>
    `
}
export default EmailTemplate;
