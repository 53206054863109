import React, {useState} from "react";
import "./Contact.css";
import "./ContactMediaquery.css";
import { MdCall, MdLocationOn } from "react-icons/md";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaFacebookSquare, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { CheckPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import axios from "axios";
import EmailTemplate from "./EmailTemplate";
import Swal from "sweetalert2";

const Contact = () => {
  const data = [{
    "label": "Hospitality",
    "value": "Hospitality",
  },{
    "label": "Research Firms",
    "value": "Research Firms",
  },{
    "label": "Real-estate & Investors",
    "value": "Real-estate & Investors",
  },{
    "label": "Event Planners",
    "value": "Event Planners",
  },{
    "label": "Travel Bureau & Government",
    "value": "Travel Bureau & Government",
  },{
    "label": "Corporate Sponsors & Marketers",
    "value": "Corporate Sponsors & Marketers",
  },{
    "label": "Cab Rentals & Logistics",
    "value": "Cab Rentals & Logistics",
  },{
    "label": "Airlines & Airports",
    "value": "Airlines & Airports",
  }]
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    services: []
  })
  const handleSubmitForm = async(e) =>{
    try{
      e.preventDefault();
      await axios.post("https://miitap.me/api/contacts/send-mail", {
        to: "atul@10times.com",
        subject: `evenTELI Lead`,
        body: EmailTemplate({
          name: `${state.firstName} ${state.lastName}`,
          email: state.email,
          phone: state.phone,
          services: state.services.join(", ")
        })
      })
      await axios.post("https://miitap.me/api/contacts/send-mail", {
        to: "prateek@10times.com",
        subject: `evenTELI Lead`,
        body: EmailTemplate({
          name: `${state.firstName} ${state.lastName}`,
          email: state.email,
          phone: state.phone,
          services: state.services.join(", ")
        })
      })
      Swal.fire("We have received your message", "We will contact you shortly", "success").then(()=>{
        setState({
          ...state,
          firstName: "",
          lastName: "",
          mail: "",
          phone: "",
          services: []
        })
      })
    }catch(err){
      console.log(err)
    }
  }
  return (
    <div className="contactus container-fluid" id="contact">
      <div className="container" style={{ marginBottom: "5rem" }}>
        <div className="row text-center imp-points marg-7">
          <h2 className="contact_heading">Contact Us</h2>
        </div>
        <div className="row text-center">
          <p className="contact_subheading">
            Any question or remarks? Just write us a message!
          </p>
        </div>
        <div className="card login-card">
          <div className="row no-gutters">
            <div className="col-md-5 lefthand-side">
              <h5>Contact Information</h5>
              <p className="content-text">
                Fill up the form and our team will get back to you within 24hr.
              </p>

              <div className="d-flex flex-column cont-details">
                <div className="d-flex flex-row my-xl-2 my-lg-2 my-md-2">
                  <MdCall className="contact-icons" />
                  <p className="contact-details">+1 463.221.4176</p>
                </div>
                <div className="d-flex flex-row my-xl-2 my-lg-2 my-md-2">
                  <BsFillEnvelopeFill className="contact-icons" />
                  <p className="contact-details">intel@10times.com</p>
                </div>
                <div className="d-flex flex-row my-xl-2 my-lg-2 my-md-2">
                  <MdLocationOn className="contact-icons" />
                  <p className="contact-details">E-75, 1st Floor, Sector 63, Noida 201307, India</p>
                </div>
              </div>
              <div className="d-flex flex-row social-contact">
                <a href="https://www.facebook.com/10timesonline" target="_blank" rel="noreferrer">
                  <FaFacebookSquare className="social-icons" />
                </a>
                <a href="https://twitter.com/10_times" target="_blank" rel="noreferrer">
                  <FaTwitter className="social-icons" />
                </a>
                <a href="https://www.linkedin.com/company/10times-events/" target="_blank" rel="noreferrer">
                  <FaLinkedinIn className="social-icons" />
                </a>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
              <div className="card-body">
                <form className="contact-form" onSubmit={handleSubmitForm}>
                  <div className="row mb-xl-3 mb-lg-3 mb-md-3">
                    <div className="form-group col-xl-6 col-lg-6 col-md-6">
                      <label htmlFor="FirstName">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="FirstName"
                        placeholder="First Name"
                        value={state.firstName}
                        onChange={(e)=>setState({
                          ...state,
                          firstName: e.target.value
                        })}
                        required
                      />
                    </div>
                    <div className="form-group col-xl-6 col-lg-6 col-md-6">
                      <label htmlFor="Lastname">Last name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="Lastname"
                        placeholder="Last Name"
                        value={state.lastName}
                        onChange={(e)=>setState({
                          ...state,
                          lastName: e.target.value
                        })}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-xl-3 mb-lg-3 mb-md-3">
                    <div className="form-group col-xl-6 col-lg-6 col-md-6">
                      <label htmlFor="Email">Mail</label>
                      <input
                        type="email"
                        className="form-control"
                        id="Email"
                        placeholder="Email"
                        value={state.email}
                        onChange={(e)=>setState({
                          ...state,
                          email: e.target.value
                        })}
                        required
                      />
                    </div>
                    <div className="form-group col-xl-6 col-lg-6 col-md-6">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        placeholder="Phone"
                        value={state.phone}
                        onChange={(e)=>setState({
                          ...state,
                          phone: e.target.value
                        })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <p className="radio-btns-text">
                      What kind of service do you need?
                    </p>
                  </div>
                  <CheckPicker 
                    data={data} 
                    style={{ width: "100%", paddingRight: 10 }} 
                    searchable={false}
                    onSelect={value=>
                     setState({
                        ...state,
                        services: value
                      })
                    }
                    value={state.services}
                  />
                  <div className="form-btn">
                    <button type="submit" className="btn btn-sendMessage">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
