import React, { useState, useEffect } from "react";
import "./Nodata.css";
import "./NodataMediaquery.css";

const Nodata = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className="container-fluid Nodata-section">
      <div className="container">
        <div
          className="row background-graphics d-flex align-content-end"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/graphics.png"})`,
          }}>
          <div className="container">
            <h2 className="Nodata_heading text-center">
              Incomplete data is worse than no data
            </h2>
            <div className="row text-center">
              <p className="Nodata_subheading">
                Data with gaps lead to poor predictions and faulty planning.
                Use eventeli, intelligence built over a decade, designed to
                upgrade your research effort from aggregating to understanding
              </p>
            </div>
          </div>
        </div>
        <div className="_points row d-flex justify-content-xl-around justify-content-lg-between justify-content-md-between mb-xl-4 mb-lg-4 mb-md-4">
          <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
            <div className="col-pink ">
              <p>
                No more <strong>manually searching</strong> and missing out on
                events
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-center">
            <div className=" col-blue">
              <p>
                Get rid of <b>multiple excel sheets</b> to unearth one right insight
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-start">
            <div className="col-pink ">
              <p>
                Eliminate the need for <b>multiple sources</b> to understand event impact
              </p>
            </div>
          </div>
        </div>

        <div className="_points row d-flex justify-content-xl-around justify-content-lg-between justify-content-md-between ">
          <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
            <div className="col-blue">
              <p>
                Quickly <b>identify and remove duplicates</b> from wrong sources
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-center">
            <div className="col-pink">
              <p>
                No more constant checking to <b>capture cancellation</b> & date changes
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-start">
            <div className="col-blue">
              <p>
                Dont fall for <strong>fake event date</strong> or miss the
                hidden events
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nodata;
