import React, { useRef, useState, useEffect } from "react";
import DataPointsUsecase from "../DataPointsUsecase/DataPointsUsecase";
import DataUsable from "../DataUsable/DataUsable";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import Pricing from "../Pricing/Pricing";
import ThreePoints from "../ThreePoints/ThreePoints";

const Usecases = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [activePage, setActivePage] = useState(0);
  const pageContent = [{
    index: 0,
    heading: "Hospitality Industry",
    para: `HoReCa industry is highly impacted by events and need data intel on upcoming trends. Till now this was done with manual scavenging or scraping from multiple sources. Let's change the process with a reliable data intelligence source to assist with planning, forecasting and investing. 

    evenTELI is your in-house data research team to help identify demand trends influenced by events.`,
    image: "hotel.svg",
    intelData: [{
      heading: "How can data intelligence make a difference:",
      points: [
        "Price Optimization",
        "Workforce management",
        "Inventory Management",
        "Marketing & Partnerships",
      ]
    },{
      heading: "Understand demand to better forecast & plan:",
      points: [
        "Number of events and its impact",
        "What are the major events - list",
        "Where are events located",
        "Filter demand by type of event",
        "Dates have high or lean demand"
      ]
    }]
  }, {
    index: 1,
    heading: "Real-estate & Investors",
    para: `Investors in real-estate, industry or event industry, can gain from better insights on the historic and future trends. Till now this was done with manual scavenging or scraping from multiple sources. Let's change the process with a reliable data intelligence source to assist with planning, forecasting and investing. 

    evenTELI is your in-house data research team to help identify demand trends influenced by events. Unlock the unlimited possibilities, make better data driven decisions.`,
    image: "skyscraper.svg",
    intelData: [{
      heading: "How can data intelligence make a difference:",
      points: [
        "Understand growth pattern in different industries",
        "Identify regional and location specific growth",
        "Scope out potential event brands to acquire or launch"
      ]
    },{
      heading: "Understand demand & ranking across industry and regions:",
      points: [
        "Number of events hosted",
        "Inbound or outbound traffic pattern",
        "Overall Audience Demand growth and ranking",
        "Where are events happening on the map",
        "Filter demand trends by type of event",
        "What dates have high or lean demand",
      ]
    }]
  }, {
    index: 2,
    heading: "Event Planners",
    para: `Event Planners and strategists can gain from better insights on the historic and future trends. Till now this was done with manual scavenging or scraping from multiple sources. Let's change the process with a reliable data intelligence source to assist with planning and forecasting. 

    evenTELI is your in-house data research team to help identify demand trends influenced by events. Unlock the unlimited possibilities, make better data driven decisions.`,
    image: "festival.svg",
    intelData: [{
      heading: "How can data intelligence make a difference:",
      points: [
        "Avoid high traffic & congestion days when choosing event days",
        "Prevent audience split by avoiding competing event days",
        "Identify regional with growing demand for marketing",
        "Find partners for upcoming or planned events",
        "Understand inbound-outbound demand patterns",
        "Identify regions & sectors for expansion basis demand",
      ]
    },{
      heading: "Understand demand & ranking across industry and regions:",
      points: [
        "Number of events hosted",
        "Inbound or outbound traffic pattern",
        "Overall Audience Demand growth and ranking",
        "Where are events happening on the map",
        "Filter demand trends by type of event",
        "What dates have high or lean demand",
      ]
    }]
  }, {
    index: 3,
    heading: "Research Firms",
    para: `Researchers and analytics can gain from better insights on the historic and future trends. Till now this was done with manual scavenging or scraping from multiple sources. Let's change the process with a reliable data intelligence source to assist with planning, forecasting and research. 

    evenTELI is your in-house data research team to help identify demand trends influenced by events. Unlock the unlimited possibilities, make better data driven decisions.`,
    image: "stock-market-2.svg",
    intelData: [{
      heading: "How can data intelligence make a difference:",
      points: [
        "A ready to use platform to do advanced research",
        "Understand trends in a region across industries",
        "Identify patterns in an industry across regions",
        "Create reports or custom study for strategic clients"
      ]
    },{
      heading: "Understand demand & ranking across industry and regions:",
      points: [
        "Number of events hosted",
        "Inbound or outbound traffic pattern",
        "Overall Audience Demand growth and ranking",
        "Where are events happening on the map",
        "Filter demand trends by type of event"
      ]
    }]
  }, {
    index: 4,
    heading: "Travel Bureau & Government",
    para: `Travel Bureau and local government bodies can gain from better insights on event trends, both in their region or outside. Till now this was done with manual scavenging or scraping from multiple sources. Let's change the process with a reliable data intelligence source to assist with planning, forecasting and research. 

    evenTELI is your in-house data research team to help identify demand trends influenced by events. Unlock the unlimited possibilities, make better data driven decisions.`,
    image: "road-sign.svg",
    intelData: [{
      heading: "How can data intelligence make a difference:",
      points: [
        "Understand event trends in a region across industries",
        "Know high traffic & congestion days for better management",
        "Understand inbound-outbound demand patterns",
        "Identify external regions & sectors for promotional activities"
      ]
    },{
      heading: "Understand demand & ranking across industry and regions:",
      points: [
        "Number of events hosted",
        "Inbound or outbound traffic pattern",
        "Overall Audience Demand growth and ranking",
        "Where are events happening on the map",
        "Filter demand trends by type of event"
      ]
    }]
  }, {
    index: 5,
    heading: "Sponsors & Marketers",
    para: `Teams deciding on sponsoring and marketing budgets can identify regions, industries and events that match their audience profile. Till now this was done with manual scavenging or scraping from multiple sources or word of mouth. Let's change the process with a reliable data intelligence source to assist with planning, forecasting and research. 

    evenTELI is your in-house data research team to help identify demand trends influenced by events. Unlock the unlimited possibilities, make better data driven decisions.`,
    image: "bookmark.svg",
    intelData: [{
      heading: "How can data intelligence make a difference:",
      points: [
        "Understand event trends in a region across industries",
        "Understand inbound-outbound demand patterns",
        "Identify regions & sectors for promotional activities",
        "Find upcoming events that match audience profile",
      ]
    },{
      heading: "Understand demand & ranking across industry and regions:",
      points: [
        "Number of events hosted",
        "Inbound or outbound traffic pattern",
        "Overall Audience Demand growth and ranking",
        "Where are events happening on the map",
        "Filter demand trends by type of event"
      ]
    }]
  }, {
    index: 6,
    heading: "Cab Rental Companies",
    para: `Cab rental companies need to plan inventory deployment and having reliable event data intel can be very handy. Till now this was done with manual scavenging or scraping from multiple sources. Let's change the process with a reliable data intelligence source to assist with planning, forecasting and investing.

    evenTELI is your in-house data research team to help identify demand trends influenced by events. Unlock the unlimited possibilities, make better data driven decisions. `,
    image: "uber-app.svg",
    intelData: [{
      heading: "How can data intelligence make a difference:",
      points: [
        "Price Optimization",
        "Workforce management",
        "Cab Deployment by location",
        "Marketing & Partnerships",
      ]
    },{
      heading: "Understand demand to better forecast & plan:",
      points: [
        "Number of events and its impact",
        "What are the major events - list",
        "Where are events - map",
        "Filter demand trends by type of event",
        "What dates are have high or lean demand"
      ]
    }]
  }, {
    index: 7,
    heading: "Airlines & Airports",
    para: `Airlines & Airports need data that can help forecast high demand and plan better. Till now this could be done with manual scavenging or scraping from multiple sources. Let's change the process with a reliable data intelligence source to assist with planning, forecasting and investing.

    evenTELI is your in-house data research team to help identify demand trends influenced by events. Unlock the unlimited possibilities, make better data driven decisions.`,
    image: "airport.svg",
    intelData: [{
      heading: "How can data intelligence make a difference:",
      points: [
        "Price Optimization",
        "Workforce management",
        "Cab Deployment by location",
        "Marketing & Partnerships",
      ]
    },{
      heading: "Understand demand to better forecast & plan:",
      points: [
        "Number of events and its impact",
        "What are the major events - list",
        "Where are events - map",
        "Filter demand trends by type of event",
        "What dates are have high or lean demand"
      ]
    }]
  }]

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const pricingSection = useRef(null);
  const gotoPricing = () => {
    window.scrollTo({
      top: pricingSection.current.offsetTop,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (props.match?.params?.name) {
      console.log(props.match?.params?.name);
      switch (props.match?.params?.name) {
        case "hospitality":
          setActivePage(0);
        break;
        
        case "research-firms":
          setActivePage(3);
        break;

        case "real-estate-and-investors":
          setActivePage(1);
        break;

        case "event-planners":
          setActivePage(2);
        break;

        case "travel-bureau-and-government":
          setActivePage(4);
        break;

        case "corporate-sponsors-and-marketers":
          setActivePage(5);
        break;

        case "cab-rentals-and-logistics":
          setActivePage(6);
        break;

        case "airlines-and-airports":
          setActivePage(7);
        break;

        default:
        break;
      }
    }
  }, [])
  return (
    <>
      <Navigation
        gotoPricing={gotoPricing}
        heading={pageContent[activePage].heading}
        para={pageContent[activePage].para}
        button="Schedule Call"
        image={pageContent[activePage].image}
      />
      <div className=" px-0">
        {/* <Certainty /> */}

        {/* {width > 768 ? <Nodata /> : <div style={{ display: "none" }}></div>} */}

        <DataPointsUsecase 
          content={pageContent[activePage].intelData}
        />

        {width > 768 ? (
          <>
            <DataUsable />
            <ThreePoints />
          </>
        ) : (
          <div style={{ display: "none" }}></div>
        )}
      </div>

      <div ref={pricingSection}>
        <Pricing />
        <Footer />
      </div>
    </>
  );
};

export default Usecases;
