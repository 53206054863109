import "./App.css";
import "bootstrap";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/bootstrap/js/dist/popover.js";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Usecases from "./Components/Usecases/Usecases";
import ScrollToTop from "./Components/ScrollToTop";

function App() {
  return (
    <Router basename="/eventeli">
    <ScrollToTop>
      <div className="App">
        <div>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/usecase/:name" component={Usecases} />
            </Switch>
        </div>
      </div>
    </ScrollToTop>
    </Router>
  );
}

export default App;
