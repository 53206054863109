import React from "react";
import "./Footer.css";
import "./FooterMediaquery.css";
import { FaFacebookSquare, FaTwitter, FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="container-fluid bottomfooter">
      <div className="container">
        <div className="footer-data d-flex justify-content-between align-items-center">
          <div className="footer-data d-flex justify-content-between align-items-center">
            <a className="d-flex navbar-brand text-white align-items-center text-decoration-none" href="https://10times.com">
              <span>Powered by</span>
              <br/>
              <img className="footerlogo" src={process.env.REACT_APP_CDN + "/Images/10timeslogo.png"} alt="logo" />
            </a>
          </div>

          <div className="d-flex flex-row  flex-lg-row flex-md-row">
            <a href="https://www.facebook.com/10timesonline" target="_blank" rel="noreferrer">
              <FaFacebookSquare className="footer-s_links" />
            </a>
            <a href="https://twitter.com/10_times" target="_blank" rel="noreferrer">
              <FaTwitter className="footer-s_links" />
            </a>
            <a href="https://www.linkedin.com/company/10times-events/" target="_blank" rel="noreferrer">
              <FaLinkedinIn className="footer-s_links" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
