import React from "react";
import "./EventIntel.css";
import "./EventIntelMediaquery.css";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const EventIntel = () => {
  return (
    <div className="eventIntel_section container-fluid ">
      <div className="container">
        <div className="row text-center imp-points">
          <h2 className="event_heading">Industries that Need Event Intel</h2>
        </div>
        <div className="row text-center">
          <p className="data_subheading">
            Events play a major role in local commerce and pegged to be a
            $Trillion Industry as a whole. See below the usecases where data can
            be a game changer
          </p>
        </div>
        <div className="row pt-3">
          {/* --- */}
          <div className="col-xl-6 col-lg-6  col-md-12">
            <div
              className="Bg-illiustration "
              style={{ backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/Group_84.png"})` }}
            >
              <div>
                <img
                  src={process.env.REACT_APP_CDN + "/Images/hotel.svg"}
                  alt="hotel"
                  className="event-points-icons"
                />
              </div>

              <div className="event-content first">
                <h5>Hospitality</h5>
                <p className="info">
                  Know demand that influences inventory planning, manpower
                  requirement and pricing
                </p>
                <div className="d-flex flex-row  align-items-center">
                  <Link to="usecase/hospitality">
                    <p className="learn-more">Learn more</p>
                  </Link>
                  <BsArrowRight className="learn-more-arrow" />
                </div>
              </div>
            </div>
          </div>
          {/* --- */}
          {/* --- */}
          <div className="col-xl-6 col-lg-6  col-md-12">
            <div
              className="Bg-illiustration "
              style={{ backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/Group_92.png"})` }}
            >
              <img
                src={process.env.REACT_APP_CDN + "/Images/stock-market-2.svg"}
                alt="stock-market-2"
                className="event-points-icons"
              />

              <div className="event-content first">
                <h5>Research Firms</h5>
                <p className="info">
                  Get data insights to understand industry and regional trends
                  over time and how the future looks
                </p>
                <div className="d-flex flex-row  align-items-center">
                  <Link to="usecase/research-firms">
                    <p className="learn-more">Learn more</p>
                  </Link>
                  <BsArrowRight className="learn-more-arrow" />
                </div>
              </div>
            </div>
          </div>
          {/* --- */}
          {/* --- */}
          <div className="col-xl-6 col-lg-6  col-md-12">
            <div
              className="Bg-illiustration "
              style={{ backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/Group_93.png"})` }}
            >
              <img
                src={process.env.REACT_APP_CDN + "/Images/skyscraper.svg"}
                alt="skyscraper"
                className="event-points-icons"
              />

              <div className="event-content second">
                <h5>Real-estate & Investors</h5>
                <p className="info">
                  Track growth or decline across different geo-location and
                  identify potential investments & acquisitions
                </p>
                <div className="d-flex flex-row  align-items-center">
                  <Link to="usecase/real-estate-and-investors">
                    <p className="learn-more">Learn more</p>
                  </Link>
                  <BsArrowRight className="learn-more-arrow" />
                </div>
              </div>
            </div>
          </div>
          {/* --- */}
          {/* --- */}
          <div className="col-xl-6 col-lg-6  col-md-12">
            <div
              className="Bg-illiustration "
              style={{ backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/Group_84.png"})` }}
            >
              <img
                src={process.env.REACT_APP_CDN + "/Images/festival.svg"}
                alt="festival"
                className="event-points-icons"
              />

              <div className="event-content second">
                <h5>Event Planners </h5>
                <p className="info">
                  Track competitors, identify partners and better plan dates to
                  generate maximum demand
                </p>
                <div className="d-flex flex-row  align-items-center">
                  <Link to="usecase/event-planners">
                    <p className="learn-more">Learn more</p>
                  </Link>
                  <BsArrowRight className="learn-more-arrow" />
                </div>
              </div>
            </div>
          </div>
          {/* --- */}
          {/* --- */}
          <div className="col-xl-6 col-lg-6  col-md-12">
            <div
              className="Bg-illiustration "
              style={{ backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/Group_88.png"})` }}
            >
              <img
                src={process.env.REACT_APP_CDN + "/Images/road-sign.svg"}
                alt="road-sign"
                className="event-points-icons"
              />

              <div className="event-content third">
                <h5>Travel Bureau & Government</h5>
                <p className="info">
                  Get early visibility into high traffic or international
                  audience inflow days. Track other competing destinations
                </p>
                <div className="d-flex flex-row  align-items-center">
                  <Link to="usecase/travel-bureau-and-government">
                    <p className="learn-more">Learn more</p>
                  </Link>
                  <BsArrowRight className="learn-more-arrow" />
                </div>
              </div>
            </div>
          </div>
          {/* --- */}
          {/* --- */}
          <div className="col-xl-6 col-lg-6  col-md-12">
            <div
              className="Bg-illiustration "
              style={{ backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/Group_93.png"})` }}
            >
              <img
                src={process.env.REACT_APP_CDN + "/Images/bookmark.svg"}
                alt="bookmark"
                className="event-points-icons"
              />

              <div className="event-content third">
                <h5>Corporate Sponsors & Marketers</h5>
                <p className="info">
                  Make better decisions when selecting events with audience
                  match. Or track high traffic days by geo-location
                </p>
                <div className="d-flex flex-row  align-items-center">
                  <Link to="usecase/corporate-sponsors-and-marketers">
                    <p className="learn-more">Learn more</p>
                  </Link>
                  <BsArrowRight className="learn-more-arrow" />
                </div>
              </div>
            </div>
          </div>
          {/* --- */}
          {/* --- */}
          <div className="col-xl-6 col-lg-6  col-md-12">
            <div
              className="Bg-illiustration "
              style={{ backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/Group_92.png"})` }}
            >
              <img
                src={process.env.REACT_APP_CDN + "/Images/uber-app.svg"}
                alt="uber-app"
                className="event-points-icons"
              />

              <div className="event-content fourth">
                <h5>Cab Rentals & Logistics</h5>
                <p className="info">
                  Manage cab availability and area allocation with insight into
                  high-low demand days and projected trends
                </p>
                <div className="d-flex flex-row  align-items-center">
                  <Link to="usecase/cab-rentals-and-logistics">
                    <p className="learn-more">Learn more</p>
                  </Link>
                  <BsArrowRight className="learn-more-arrow" />
                </div>
              </div>
            </div>
          </div>
          {/* --- */}
          {/* --- */}
          <div className="col-xl-6 col-lg-6  col-md-12">
            <div
              className="Bg-illiustration "
              style={{ backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/Group_87.png"})` }}
            >
              <img
                src={process.env.REACT_APP_CDN + "/Images/airport.svg"}
                alt="airport"
                className="event-points-icons"
              />

              <div className="event-content fourth">
                <h5>Airlines & Airports</h5>
                <p className="info">
                  Understand days when high inbound traffic expected and also
                  monetize the ad inventory
                </p>
                <div className="d-flex flex-row  align-items-center">
                  <Link to="usecase/airlines-and-airports">
                    <p className="learn-more">Learn more</p>
                  </Link>
                  <BsArrowRight className="learn-more-arrow" />
                </div>
              </div>
            </div>
          </div>
          {/* --- */}
        </div>
      </div>
    </div>
  );
};

export default EventIntel;
