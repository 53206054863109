import React, { useState, useEffect, useRef } from "react";
import Certainty from "../Certainty/Certainty";
import Contact from "../Contact/Contact";
import DataPoints from "../DataPoints/DataPoints";
import DataUsable from "../DataUsable/DataUsable";
import EventIntel from "../EventIntel/EventIntel";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";

import Nodata from "../Nodata/Nodata";
import Pricing from "../Pricing/Pricing";
import ThreePoints from "../ThreePoints/ThreePoints";

const Home = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const pricingSection = useRef(null);
  const contactSection = useRef(null);
  const gotoPricing = () => {
    window.scroll({
      top: pricingSection.current.offsetTop,
      behavior: "smooth",
    });
  };
  const gotoContact = () => {
    window.scroll({
      top: contactSection.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <Navigation
        gotoPricing={gotoPricing}
        gotoContact={gotoContact}
        heading="Event Data Intelligence"
        para="evenTELI is your in-house data research team to help identify demand trends influenced by events. Unlock the unlimited possibilities, make better data driven decisions."
        button="KNOW MORE"
        image="suburbs.svg"
      />
      <div className="container-fluid px-0">
        <Certainty />
        {width > 768 ? (
          <>
            <Nodata />
          </>
        ) : (
          <div style={{ display: "none" }}></div>
        )}{" "}
        <DataPoints />
        {width > 768 ? (
          <>
            <EventIntel />
          </>
        ) : (
          <div style={{ display: "none" }}></div>
        )}
        <DataUsable />
        <ThreePoints />
      </div>

      <div className="container-fluid px-0">
        <div ref={pricingSection}>
          <Pricing />
        </div>
        <div ref={contactSection}>
          <Contact />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
