import React from "react";
import "./DataPoints.css";
import "./DataPointsMediaquery.css";

const DataPointsUsecase = (props) => {
  return (
    <div className="container-fluid Datapoints-section px-0">
      <div className="container">
        <div className="row text-center imp-points">
          <h2 className="data_heading">Intel from a Billion+ Data Points</h2>
        </div>
        <div className="row text-center">
          <p className="data_subheading">
            evenTELI is your in-house data research team to help identify demand trends influenced by events. Unlock the unlimited possibilities, make better data driven decisions.
          </p>
        </div>
        {props.content.map((item, i) => {
          if (i % 2) {
            return (
              <div className="row spacing-top d-flex flex-xl-row flex-lg-row flex-md-row  align-items-center" key={i}>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <img
                    src={process.env.REACT_APP_CDN + "/Images/website.svg"}
                    alt="Collated"
                    className="data_Points_Img"
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 Image-content-sec">
                  <div>
                    <div className="row ">
                      <h2 className="Points-Heading">{item.heading}</h2>
                    </div>
                    <div className="listed-points">
                      <ul>
                        {item.points.map((point, j) => (
                          <li className="Points-lists" key={j}>
                            {point}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )
          } else {
            return (
              <div className="row spacing-top d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column-reverse flex-column-reverse  align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 Image-content-sec">
                  <div>
                    <div className="row ">
                      <h2 className="Points-Heading">{item.heading}</h2>
                    </div>
                    <div className="listed-points">
                      <ul>
                      {item.points.map((point, j) => (
                          <li className="Points-lists" key={j}>
                            {point}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <img
                    src={process.env.REACT_APP_CDN + "/Images/wishlist.svg"}
                    alt="wishlist"
                    className="data_Points_Img"
                  />
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  );
};

export default DataPointsUsecase;
