import React from "react";
import "./ThreePoints.css";
import "./ThreePointsMediaquery.css";

const ThreePoints = () => {
  return (
    <div className="three-points container-fluid">
      <div className="container">
        <div className="row marg-7">
          <div className="col-xl-4 col-lg-4 col-md-4 pad-20">
            <div className="content-container">
              <img
                src={process.env.REACT_APP_CDN + "/Images/testing.svg"}
                alt="Icon-001"
                className="three-points-img"
              />
              <h5 className="title-text">Real-time</h5>
              <p className="para-text">
                Start ahead with data that is refreshed in real-time.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 pad-20">
            <div className="content-container">
              <img
                src={process.env.REACT_APP_CDN + "/Images/upvote.svg"}
                alt="Calculator"
                className="three-points-img"
              />
              <h5 className="title-text">Scalable</h5>
              <p className="para-text">
              Choose a plan that is easy to scale from one city/industry to global
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 pad-20">
            <div className="content-container">
              <img
                src={process.env.REACT_APP_CDN + "/Images/work-chat.svg"}
                alt="Icon003"
                className="three-points-img"
              />
              <h5 className="title-text">Custom</h5>
              <p className="para-text">
                Get support and customize your view to meet your requirement
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreePoints;
