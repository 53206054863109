import React, { useState, useEffect } from "react";
import "./Pricing.css";
import "./PricingMediaquery.css";

import ReactCardCarousel from "react-card-carousel";

const Pricing = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const prices_card = {
    height: "300px",
    width: "230px",
    textAlign: "center",
    background: "#fff",
    borderRadius: "20px",
    boxSizing: "border-box",
  };

  const prices_container_style = {
    position: "relative",
    height: "500px",
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "middle",
  };

  return (
    <div
      className="pricing_section container-fluid pricing-background"
      style={{ backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/pricingbg.png"})` }}>
      <div className="container space-top">
        <img
          src={process.env.REACT_APP_CDN + "/Images/Subtract.png"}
          alt="Subtract"
          className="pricingright-illiustration"
        />
        <div className="row text-center imp-points">
          <h2 className="pricing_heading">Pricing Plans</h2>
        </div>
        <div className="row text-center">
          <p className="pricing_subheading">
            Pricing based on industry and region. Choose whats relevant or get
            full access to not miss out on any insight.
          </p>
        </div>
        {/* destop */}
        <div className="row cards-section">
          <div className="col-xl-4 col-lg-4 col-md-4">
            <div className="card text-center pricing-cards">
              <div className="card-body">
                <div>
                  <h4 className="card-title">Geography API</h4>
                  <p className="sub-text">Select key City</p>
                </div>
                <hr />
                <div className="d-flex flex-column">
                  <h3 className="prices">$99</h3>
                  <p className="sub-text">per month</p>
                </div>
                <a className="btn btn-order text-decoration-none" role="button" href={process.env.PUBLIC_URL + "#contact"}>
                  Order
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4">
            <div className="card text-center selected-pricing-cards">
              <div className="card-body">
                <div>
                  <h4 className="card-title">Industry API</h4>
                  <p className="sub-text">Select Category</p>
                </div>
                <hr />
                <div className="d-flex flex-column">
                  <h3 className="prices">$499</h3>
                  <p className="sub-text">per month</p>
                </div>
                <a className="btn btn-order text-decoration-none" role="button" href={process.env.PUBLIC_URL + "#contact"}>
                  Order
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4">
            <div className="card text-center pricing-cards">
              <div className="card-body">
                <div>
                  <h4 className="card-title">All Access API</h4>
                  <p className="sub-text">Includes All Industries &amp; geo</p>
                </div>
                <hr />
                <div className="d-flex flex-column">
                  <h3 className="prices">$4999</h3>
                  <p className="sub-text">per month</p>
                </div>
                <a className="btn btn-order text-decoration-none" role="button" href={process.env.PUBLIC_URL + "#contact"}>
                  Order
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* end destop */}

        {/* mobile */}
        <div className="row mobile-section">
          <div style={prices_container_style}>
            <ReactCardCarousel autoplay={true} autoplay_speed={2000}>
              <div style={prices_card}>
                <div className="card-body">
                  <h4
                    style={{
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#172755",
                    }}>
                    Geography API
                  </h4>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: 14,
                      color: "#8794ba",
                    }}>
                    Select key City
                  </p>
                  <hr />
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center m-auto">
                      <p
                        style={{
                          fontSize: 24,
                          fontWeight: "700",
                          fontStyle: "normal",
                          color: "#2a407c",
                          paddingTop: 14,
                        }}>
                        $
                      </p>
                      <h3
                        style={{
                          fontSize: 42,
                          fontStyle: "normal",
                          fontWeight: 700,
                          color: "#2a407c",
                        }}>
                        99
                      </h3>
                    </div>
                    <p>per month</p>
                  </div>
                  <a className="btn" role="button" href={process.env.PUBLIC_URL + "#contact"} style={{
                      background: "#ffbe55",
                      color: "#fff",
                      paddingRight: 50,
                      paddingLeft: 50,
                      paddingTop: 5,
                      paddingBottom: 5,
                      borderRadius: 20,
                      fontSize: 16,
                      marginTop: 10,
                      fontWeight: "600",
                    }}>
                  Order
                </a>
                </div>
              </div>
              {/* --- */}
              <div style={prices_card}>
                <div className="card-body">
                  <h4
                    style={{
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#172755",
                    }}>
                    Industry API
                  </h4>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: 14,
                      color: "#8794ba",
                    }}>
                    Select Category
                  </p>
                  <hr />
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center m-auto">
                      <p
                        style={{
                          fontSize: 24,
                          fontWeight: "700",
                          fontStyle: "normal",
                          color: "#2a407c",
                          paddingTop: 14,
                        }}>
                        $
                      </p>
                      <h3
                        style={{
                          fontSize: 42,
                          fontStyle: "normal",
                          fontWeight: 700,
                          color: "#2a407c",
                        }}>
                        499
                      </h3>
                    </div>
                    <p>per month</p>
                  </div>
                  <a className="btn" role="button" href={process.env.PUBLIC_URL + "#contact"} style={{
                      background: "#ffbe55",
                      color: "#fff",
                      paddingRight: 50,
                      paddingLeft: 50,
                      paddingTop: 5,
                      paddingBottom: 5,
                      borderRadius: 20,
                      fontSize: 16,
                      marginTop: 10,
                      fontWeight: "600",
                    }}>
                  Order
                </a>
                </div>
              </div>
              {/* --- */}
              <div style={prices_card}>
                <div className="card-body">
                  <h4
                    style={{
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#172755",
                    }}>
                    All Access API
                  </h4>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: 14,
                      color: "#8794ba",
                    }}>
                    Includes All Industries &amp; Geo
                  </p>
                  <hr />
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center m-auto">
                      <p
                        style={{
                          fontSize: 24,
                          fontWeight: "700",
                          fontStyle: "normal",
                          color: "#2a407c",
                          paddingTop: 14,
                        }}>
                        $
                      </p>
                      <h3
                        style={{
                          fontSize: 42,
                          fontStyle: "normal",
                          fontWeight: 700,
                          color: "#2a407c",
                        }}>
                        4999
                      </h3>
                    </div>
                    <p>per month</p>
                  </div>
                  <a className="btn" role="button" href={process.env.PUBLIC_URL + "#contact"} style={{
                      background: "#ffbe55",
                      color: "#fff",
                      paddingRight: 50,
                      paddingLeft: 50,
                      paddingTop: 5,
                      paddingBottom: 5,
                      borderRadius: 20,
                      fontSize: 16,
                      marginTop: 10,
                      fontWeight: "600",
                    }}>
                  Order
                </a>
                </div>
              </div>
            </ReactCardCarousel>
          </div>
        </div>

        <div className="row text-center">
          <p className="pricing_note">
            Note: we only provide yearly license
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
