import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown, NavItem } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./Navigation.css";
import "./NavigationMediaquery.css";

const Navigation = ({
  heading,
  para,
  button,
  gotoPricing,
  gotoContact,
  image,
}) => {
  // const [click, setClick] = React.useState(false);

  // const handleClick = () => setClick(!click);
  // const Close = () => setClick(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const history = useHistory();

  return (
    <div
      className="container-fluid banner-background "
      style={{ backgroundImage: `url(${process.env.REACT_APP_CDN + "/Images/headerBgImage2.png"})` }}
    >
      {/* Banner-section */}
      <div className="container pt-lg-4 pt-md-4">
        <img className="topleft-illiustration" src={process.env.REACT_APP_CDN + "/Images/topleft-illiustration.png"} alt="topleft-illiustration" />
        
        <Navbar collapseOnSelect expand="lg" className="navigation ">
          <Container>
            <Navbar.Brand onClick={() => history.push("/")} className="d-flex">
              <img src={process.env.REACT_APP_CDN +  "/Images/startup-growth.svg"} alt="logo" className="websitelogo" />
              <span className="logo_name">evenTELI</span>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="m-auto">
                <NavDropdown id="navbarScrollingDropdown" title="Usecases">
                  <NavDropdown.Item className="text-decoration-none" href={process.env.PUBLIC_URL + "/usecase/hospitality"}>
                    Hospitality
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  <NavDropdown.Item className="text-decoration-none" href={process.env.PUBLIC_URL + "/usecase/research-firms"}>
                    Research Firms
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  <NavDropdown.Item className="text-decoration-none" href={process.env.PUBLIC_URL + "/usecase/real-estate-and-investors"}>
                    Real-estate &amp; Investors
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  <NavDropdown.Item className="text-decoration-none" href={process.env.PUBLIC_URL + "/usecase/event-planners"}>
                    Event Planners
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  <NavDropdown.Item className="text-decoration-none" href={process.env.PUBLIC_URL + "/usecase/travel-bureau-and-government"}>
                    Travel Bureau &amp; Government
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  <NavDropdown.Item className="text-decoration-none" href={process.env.PUBLIC_URL + "/usecase/corporate-sponsors-and-marketers"}>
                    Sponsors &amp; Marketers
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  <NavDropdown.Item className="text-decoration-none" href={process.env.PUBLIC_URL + "/usecase/cab-rentals-and-logistics"}>
                    Cab Rentals &amp; Logistics
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  <NavDropdown.Item className="text-decoration-none" href={process.env.PUBLIC_URL + "/usecase/airlines-and-airports"}>
                    Airlines &amp; Airports
                  </NavDropdown.Item>
                </NavDropdown>

                <NavItem className="nav-link">|</NavItem>

                <Nav.Link className="text-decoration-none" onClick={gotoPricing} >
                  Pricing
                </Nav.Link>

                <NavItem className="nav-link">|</NavItem>

                <Nav.Link className="text-decoration-none" href="https://10times.com/top100">
                  Top Events
                </Nav.Link>

                <NavItem className="nav-link">|</NavItem>

                <Nav.Link className="text-decoration-none" href="https://10times.com/about" target="_blank" rel="noreferrer">
                  About Us
                </Nav.Link>
              </Nav>
              <Nav>
                {/* <Nav.Link href="#deets">Sign in</Nav.Link> */}
                <Nav.Link  href={process.env.PUBLIC_URL + "#contact"}>
                  <button type="button" className="btn btn-demo">
                    Demo
                  </button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="section-position">
          <div className="left-content d-flex flex-column ">
            <div>
              <img
                src={process.env.REACT_APP_CDN + `/Images/${image}`}
                alt="Data Intelligence"
                className="Data_intelligence"
              />
            </div>

            <div className="intro-section">
              <h2 className="intro-heading">{heading}</h2>
              <p className="intro-subheading">{para}</p>
              <a className="btn text-decoration-none btn-Know_more" href={process.env.PUBLIC_URL + "#contact"} role="button">
                {button}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Banner-section */}
    </div>
  );
};

export default Navigation;
