import React from "react";
import "./Certainty.css";
import "./CertaintyMediaquery.css";

const Certainty = ({ heading, para, button }) => {
  return (
    <div className="container-fluid certainty-section">
      <div className="container">
        <div className="row text-center imp-points">
          <h2 className="certainty_heading">From chance to certainty</h2>
        </div>
        <div className="row text-center">
          <p className="certainty_subheading">
            Know the trends that cause demand fluctuations. Optimize your
            strategy to minimize losses and maximize profits. Find the what,
            where, who of events and how you can win.
          </p>
        </div>
        <div className="row certainty-pad">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
            <div className="text-center imp-points">
              <img
                src={process.env.REACT_APP_CDN + "/Images/referral.svg"}
                alt="Events Covered"
                className="certainty_icons"
              />
              <h4 className="icons_title">Events Covered</h4>
              <p className="icons_subtext">
                Over 500K professional events tracked in 1000+ major event hubs
              </p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
            <div className="text-center imp-points">
              <img
                src={process.env.REACT_APP_CDN + "/Images/social-event.svg"}
                alt="Audience Intel"
                className="certainty_icons"
              />
              <h4 className="icons_title">Audience Intel</h4>
              <p className="icons_subtext">
                Built with 50 million+ eventgoers intel to understand demand and quality
              </p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
            <div className="text-center imp-points">
              <img
                src={process.env.REACT_APP_CDN + "/Images/project-manager.svg"}
                alt="Organiser Active"
                className="certainty_icons"
              />
              <h4 className="icons_title">Organiser Access</h4>
              <p className="icons_subtext">
                Over 150k event planners mapped and directly use provided event knowledge
              </p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
            <div className="text-center imp-points">
              <img
                src={process.env.REACT_APP_CDN + "/Images/education-school.svg"}
                alt="Venues Mapped"
                className="certainty_icons"
              />
              <h4 className="icons_title">Venues Mapped</h4>
              <p className="icons_subtext">
                Events happening at over 70k+ venues mapped by its geo-location
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certainty;
