import React from "react";
import "./DataPoints.css";
import "./DataPointsMediaquery.css";

const DataPoints = () => {
  return (
    <div className="container-fluid Datapoints-section px-0">
      <div className="container">
        <div className="row text-center imp-points">
          <h2 className="data_heading">Intel from a Billion+ Data Points</h2>
        </div>
        <div className="row text-center">
          <p className="data_subheading">
            Not count of data, but data that counts. Our process & data models
            injest all the data to make it insightful and actionable. Our data includes three contexts: <b>Basic</b>, <b>Historic</b> & <b>Predictive</b>
          </p>
        </div>
        {/* -- */}
        <div className="row spacing-top d-flex flex-xl-row flex-lg-row flex-md-row  align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <img
              src={process.env.REACT_APP_CDN + "/Images/website.svg"}
              alt="Collated"
              className="data_Points_Img"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 Image-content-sec">
            <div>
              <div className="row ">
                <h2 className="Points-Heading">Curated Data for Accuracy</h2>
              </div>
              <div className="listed-points">
                <ul>
                  <li className="Points-lists">
                    Events are dynamic, prone to frequent changes. Reaching data
                    accuracy needs a rigorous multi-pronged approach to getting
                    and maintaining relevancy.
                  </li>
                  <li className="Points-lists">
                    Most raw event APIs are incomplete and capture data from
                    some secondary source, without any checks and balances.
                  </li>
                  <li className="Points-lists">
                    evenTELI is built over models perfected over a decade. On top of our first-hand sourcing from event planners & organizers, we supplement it with machine learning models &amp; quality checks to ensure real-time accuracy.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* -- */}
        {/* -- */}
        <div className="row spacing-top d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column-reverse flex-column-reverse  align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 Image-content-sec">
            <div>
              <div className="row ">
                <h2 className="Points-Heading">Category Focus for Impact</h2>
              </div>
              <div className="listed-points">
                <ul>
                  <li className="Points-lists">
                    Clients tell us that there is “too much noise in event space.” They love our solution because it focuses on what matters and removes the clutter.
                  </li>

                  <li className="Points-lists">
                    More data doesn't mean better insights. Our primary focus is professional events (tradeshows, conferences, summits, etc) and large format events (exhibitions, concerts, festivals, etc) that affect inbound traffic.
                  </li>

                  <li className="Points-lists">
                    This allows us to give clear indications on parameters like
                    inbound traffic and event impact score.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <img
              src={process.env.REACT_APP_CDN + "/Images/wishlist.svg"}
              alt="wishlist"
              className="data_Points_Img"
            />
          </div>
        </div>
        {/* -- */}
        {/* -- */}
        <div className="row spacing-top d-flex flex-xl-row flex-lg-row flex-md-row  align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <img
              src={process.env.REACT_APP_CDN + "/Images/problem-solving.svg"}
              alt="problem-solving"
              className="data_Points_Img"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 Image-content-sec">
            <div>
              <div className="row ">
                <h2 className="Points-Heading">Quality for Reliability</h2>
              </div>
              <div className="listed-points">
                <ul>
                  <li className="Points-lists">
                    Events are dynamic, plagued with frequent changes and
                    fragmentation, which makes insight creating extremely
                    tedious.
                  </li>

                  <li className="Points-lists">
                    It includes but is not limited to factors like mergers, co-hosting, name changes, location movement, hosting patterns, industry trends.
                  </li>

                  <li className="Points-lists">
                    We address it with multiple data models, algorithms and
                    machine learning, to detect duplicacy, spam, missing
                    information. Our models include historical data to factor in
                    vital variables such as longevity, organiser credibility,
                    event rating, industry growth.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* -- */}
        {/* -- */}
        <div className="row spacing-top d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column-reverse flex-column-reverse align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 Image-content-sec">
            <div>
              <div className="row ">
                <h2 className="Points-Heading">Enriched basis Usecase</h2>
              </div>
              <div className="listed-points">
                <ul>
                  <li className="Points-lists">
                    Depending on the usecase, various scores are generated to
                    enrich the aggregated event data, to make it more
                    actionable.
                  </li>
                  <li className="Points-lists">
                    Explore insights by date, industry or single event level.
                  </li>
                  <li className="Points-lists">
                    Gauge inbound traffic on a certain date with Inflow Score
                  </li>
                  <li className="Points-lists">
                    Predict visitors flying into a city with International
                    Score.
                  </li>
                  <li className="Points-lists">
                    Understand expected congestion in a geo-code with impact
                    score.
                  </li>
                  <li className="Points-lists">
                    See regional growth or decline with Location Ranking
                  </li>
                  <li className="Points-lists">
                    Measure Quality with user ratings and search pattern
                  </li>
                  {/* <li className="Points-lists">
                  Quality of event: Historical user ratings and search pattern.
                </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <img
              src={process.env.REACT_APP_CDN + "/Images/personal-website.svg"}
              alt="personal-website"
              className="data_Points_Img"
            />
          </div>
        </div>
        {/* -- */}
        {/* -- */}
        <div className="row spacing-top d-flex flex-xl-row flex-lg-row flex-md-row align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <img
              src={process.env.REACT_APP_CDN + "/Images/scrum-board-2.svg"}
              alt="scrum-board-2"
              className="data_Points_Img"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 Image-content-sec">
            <div>
              <div className="row ">
                <h2 className="Points-Heading">Context Overlay </h2>
              </div>
              <div className="listed-points">
                <ul>
                  <li className="Points-lists">
                    For future planning &amp; forecasting, events' impact needs to be brought to context keeping in mind other moving variables.
                  </li>

                  <li className="Points-lists">
                    We use vital data sources to consume billions of data points, to take into consideration and show ‘future’ focused contextual information that impacts geolocation.
                  </li>

                  <li className="Points-lists">
                    This includes factors like local holidays, sporting events,
                    weather patterns, other hard to predict disruptors.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* -- */}
      </div>
    </div>
  );
};

export default DataPoints;
